import './About.css';
import logo from '../../logo.svg';
export default function About() {
	return (
		<>
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>About page</p>
			</header>
		</>
	);
}
