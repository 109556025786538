import './NavBar.css';
import { PropsWithChildren } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

export interface NavBarLinkProps {
	to: string;
}

export default function NavBarLink(props: PropsWithChildren<NavBarLinkProps>) {
	const { to, children } = props;
	const resolvedPath = useResolvedPath(to);
	const isActive = useMatch({ path: resolvedPath.pathname, end: true });

	return (
		<li className={isActive ? 'active' : ''}>
			<Link to={to}>{children}</Link>
		</li>
	);
}
