import { Link } from 'react-router-dom';
import './NavBar.css';
import NavBarLink from './NavBarLink';

export default function NavBar() {
	return (
		<nav className="NavBar">
			<Link to="/" className="NavBar__SiteTitle">
				SScothorn
			</Link>
			<ul>
				<NavBarLink to="/about">About</NavBarLink>
				<NavBarLink to="/contact">Contact</NavBarLink>
			</ul>
		</nav>
	);
}
